<template>
  <v-app>
    <v-app-bar app color="primary" class="pl-md-10 px-xs-4" dark fixed>
      <v-app-bar-nav-icon @click="showSidebar = true" class="d-flex d-md-none">
      </v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-toolbar-title class="text-h4 text-md-h3"> Renase </v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <!-- <span class="mx-1">{{ $store.getters.auth }} </span> -->
      <template v-if="$store.getters.auth">
        <div class="d-none d-md-flex">
          <span class="px-4 my-1">{{ $store.getters.user.name }} </span>
        </div>
        <div class="d-none d-md-flex">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Entidades
              </v-btn>
            </template>
            <v-list>
              <v-list-item link to="/clientes">
                <v-list-item-title>
                  Clientes
                </v-list-item-title>
              </v-list-item>
              <v-list-item link to="/proveedores">
                <v-list-item-title>
                  Proveedores
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <router-link text to="/productos">
            <v-btn text>
              Productos
            </v-btn>
          </router-link>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Inventario
              </v-btn>
            </template>
            <v-list>
              <v-list-item link to="/materia-prima">
                <v-list-item-title>
                  Materia Prima
                </v-list-item-title>
              </v-list-item>
              <v-list-item link to="/producto-terminado">
                <v-list-item-title>
                  Producto Terminado
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Facturacion
              </v-btn>
            </template>
            <v-list>
              <v-list-item link to="/facturacion/ver">
                <v-list-item-title>
                  Ver
                </v-list-item-title>
              </v-list-item>
              <v-list-item link to="/facturacion/crear">
                <v-list-item-title>
                  Crear
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-none d-md-flex">
          <v-btn text @click="logout"> Cerrar sesión</v-btn>
        </div>
      </template>
      <template v-else> </template>
    </v-app-bar>

    <v-navigation-drawer v-model="showSidebar" temporary fixed>
      <v-list nav>
        <v-list-item>
          <v-list-item-content class="my-2">
            <h1>Renase</h1>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="mb-0" v-if="$store.getters.auth">
          <v-list-item-content class="my-2">
            <h3>
              {{ $store.getters.user.name }}
            </h3>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item-group
          ripple="true"
          class="mt-5"
          v-if="$store.getters.auth"
        >
          <v-list-item link to="/proveedores">
            <v-list-item-title to="/proveedores" text
              >Proveedores</v-list-item-title
            >
          </v-list-item>
          <v-list-item link to="/clientes">
            <v-list-item-title to="/clientes" text>Clientes</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/productos">
            <v-list-item-title>Productos</v-list-item-title>
          </v-list-item>

          <v-list-group :value="false" no-action>
            <template v-slot:activator>
              <v-list-item-title>Inventario</v-list-item-title>
            </template>
            <v-list-item link to="/materia-prima">
              <v-list-item-title>Materia Prima</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/producto-terminado">
              <v-list-item-title>Producto Terminado</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-group :value="false" no-action>
            <template v-slot:activator>
              <v-list-item-title>Facturacion</v-list-item-title>
            </template>
            <v-list-item link to="/facturacion/ver">
              <v-list-item-title>Ver</v-list-item-title>
            </v-list-item>
            <v-list-item link to="/facturacion/crear">
              <v-list-item-title>Crear</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title text>Cerrar Sesión</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
      <SnackBar />
    </v-main>
  </v-app>
</template>
<script>
import SnackBar from './components/SnackBar'

export default {
  name: 'App',

  components: {
    SnackBar
  },

  data: () => ({
    showSidebar: null,
    logged: false
  }),
  created: function() {
    console.log('App created')
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout')
      this.$router.push('/')
    }
  }
}
</script>
