import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)
import es from 'vuetify/es5/locale/es'

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#171a21',
        secondary: '#617073',
        accent: '#7a93ac',
        error: '#f44336',
        warning: '#f0ad4e',
        info: '#92bcea',
        success: '#2196f3'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
})
