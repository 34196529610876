import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/Register.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/productos',
    name: 'Productos',
    component: () => import('../views/Productos.vue')
  },
  {
    path: '/materia-prima',
    name: 'MateriaPrima',
    component: () => import('../views/MateriaPrima.vue')
  },
  {
    path: '/proveedores',
    name: 'Proveedores',
    component: () => import('../views/Proveedores.vue')
  },
  {
    path: '/producto-terminado',
    name: 'ProductoTerminado',
    component: () => import('../views/ProductoTerminado.vue')
  },
  {
    path: '/facturacion/crear',
    name: 'CrearFacturacion',
    component: () => import('../views/CrearFacturacion.vue')
  },
  {
    path: '/facturacion/ver',
    name: 'VerFacturacion',
    component: () => import('../views/VerFacturas.vue')
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: () => import('../views/Clientes.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const openRoutes = ['Login', 'Register']

router.beforeEach(async (to, from, next) => {
  await store.dispatch('getUser')
  console.log('Route requested: ', to.name, store.getters.auth)
  if (openRoutes.includes(to.name)) {
    if (store.getters.auth) {
      next('/home')
    } else {
      next()
    }
  } else if (store.getters.auth) {
    next()
  } else {
    next('/')
  }
})

export default router
