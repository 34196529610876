import axios from 'axios'

axios.defaults.withCredentials = true

axios.defaults.baseURL = process.env.VUE_APP_API_URL

export default {
  state: {
    reports: null,
    productName: ''
  },
  mutations: {
    SET_REPORTS(state, content) {
      console.log('Action called with parameters:', content)

      state.reports = content.providers.data
      state.productName = content.productName
    }
  },
  actions: {
    getProductReport: async function({ commit }, data) {
      let providers = await axios.get(`/api/rawmaterials/${data.id}/providers`)

      commit('SET_REPORTS', { providers, productName: data.productName })
    }
  },
  getters: {}
}
