import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import notifier from './plugins/notifier'
import requests from './plugins/requests'

Vue.config.productionTip = false

Vue.use(requests)
Vue.use(notifier)

Vue.filter('TitleCase', value => {
  return value.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
})

Vue.prototype.$warehouses = [
  { text: 'Arequipa', value: 1 },
  { text: 'Lima', value: 2 }
]

Vue.prototype.$sellers = [
  'YUNE VARGAS',
  'JAVIER BALBUENA',
  'OFICINA',
  'DRA ROXANA',
  'STEPHANY CORTEZ'
]

Vue.mixin({
  methods: {
    notEmptyObject(someObject) {
      if (someObject) return !!Object.keys(someObject).length
      return false
    },
    getSellValue(ammount) {
      return (ammount / 1.18).toFixed(2)
    },
    getIgvValue(ammount) {
      let sell_value = ammount / 1.18
      return (ammount - sell_value).toFixed(2)
    },
    getTotalPrice(unit_price, quantity, discount, is_free = false) {
      if (is_free) return (0).toFixed(2)
      let subtotal = unit_price * quantity
      let discounted = (subtotal * discount) / 100
      return (subtotal - discounted).toFixed(2)
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
