import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import products from './products'

console.log(process.env.VUE_APP_API_URL)

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    messageHandler: {
      message: '',
      color: 'info',
      duration: 3
    }
  },
  mutations: {
    showMessage(state, content) {
      state.messageHandler.message = content.message
      state.messageHandler.color = content.color
      state.messageHandler.duration = content.duration
    }
  },
  actions: {},
  getters: {},
  modules: { auth, products }
})
